import React from 'react';

function Testimonials() {
    return (
        <div id="testimonials" class="text-center">
            <div class="overlay">
                <div class="container">
                    <div class="section-title">
                        <h2>Testimonials</h2>
                        <hr/>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-md-offset-1">
                            <div id="testimonial" class="owl-carousel owl-theme">
                                <div class="item">
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at duis sed dapibus leo nec ornare diam."</p>
                                    <p>- John DOE, Parker County, TX</p>
                                </div>
                                <div class="item">
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at duis sed dapibus leo nec ornare diam."</p>
                                    <p>- Jenny DOE, Parker County, TX</p>
                                </div>
                                <div class="item">
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at duis sed dapibus leo nec ornare diam."</p>
                                    <p>- John DOE, Parker County, TX</p>
                                </div>
                                <div class="item">
                                    <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at duis sed dapibus leo nec ornare diam."</p>
                                    <p>- John DOE, Parker County, TX</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;