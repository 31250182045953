import React from 'react';

function About() {
    return (
        <div id="about">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-6">
                        <div class="about-text">
                            <h2>Welcome to <span>Landscaper</span></h2>
                            <hr />
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at. Duis sed dapibus leo nec ornare diam commodo nibh.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam. Sed commodo nibh ante facilisis bibendum dolor feugiat at. Duis sed dapibus leo nec ornare diam commodo nibh.</p>
                                <a href="#services" class="btn btn-custom btn-lg page-scroll">View All Services</a> </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="about-media"> <img src="img/garden5.jpeg" alt=" "/> </div>
                        <div class="about-desc">
                            <h3>Garden Care</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis.</p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="about-media"> <img src="img/garden6.jpeg" alt=" "/> </div>
                        <div class="about-desc">
                            <h3>Lawn Care</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;