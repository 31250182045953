import React from 'react';

function Portfolio() {
    return (
        <div id="portfolio">
            <div class="container">
                <div class="section-title text-center center">
                    <h2>Project Gallery</h2>
                    <hr/>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed dapibus leonec.</p>
                </div>
                <div class="categories">
                    <ul class="cat">
                        <li>
                            <ol class="type">
                                <li><a href="#" data-filter="*" class="active">All</a></li>
                                <li><a href="#" data-filter=".lawn">Lawn Care</a></li>
                                <li><a href="#" data-filter=".garden">Garden Care</a></li>
                                <li><a href="#" data-filter=".planting">Planting</a></li>
                            </ol>
                        </li>
                    </ul>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="portfolio-items">
                        <div class="col-sm-6 col-md-4 lawn">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden1.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Lorem Ipsum</h4>
                                    </div>
                                    <img src="img/garden1.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 planting">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden7.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Adipiscing Elit</h4>
                                    </div>
                                    <img src="img/garden7.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 lawn">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden8.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Lorem Ipsum</h4>
                                    </div>
                                    <img src="img/garden8.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 lawn">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden9.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Lorem Ipsum</h4>
                                    </div>
                                    <img src="img/garden9.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 planting">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden22.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Adipiscing Elit</h4>
                                    </div>
                                    <img src="img/garden22.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 garden">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden31.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Dolor Sit</h4>
                                    </div>
                                    <img src="img/garden31.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 garden">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden36.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Dolor Sit</h4>
                                    </div>
                                    <img src="img/garden36.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 lawn">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden34.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Lorem Ipsum</h4>
                                    </div>
                                    <img src="img/garden34.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 planting">
                            <div class="portfolio-item">
                                <div class="hover-bg"> <a href="img/garden23.jpeg" title="Project Title" data-lightbox-gallery="gallery1">
                                    <div class="hover-text">
                                        <h4>Adipiscing Elit</h4>
                                    </div>
                                    <img src="img/garden23.jpeg" class="img-responsive" alt="Project Title"/> </a> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;