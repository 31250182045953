import React from 'react';

function Footer() {
    return (
        <div id="footer">
            <div className="container text-center">
                <div className="col-md-8 col-md-offset-2">
                    <div className="social">
                        <ul>
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        </ul>
                    </div>
                    <p>&copy; 2016 Landscaper</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;